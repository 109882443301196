<template>
    <div>
        <b-card>
            <b-tabs>
                <b-tab>
                    <template #title>
                        <feather-icon icon="HomeIcon" />
                        <span>General</span>
                    </template>

                    <b-container
                        class="mt-2"
                        fluid
                    >
                        <b-row
                            class="mb-1"
                        >
                            <b-col sm="2">
                                Name of user group:
                            </b-col>
                            <b-col sm="6">
                                <b-form-input
                                    v-model="groupData.name"
                                    placeholder="Name of user group (ex. Super admin) or (ex. Fulfillment room)"
                                />
                            </b-col>
                        </b-row>
                        <b-row
                            class="mb-1 text-right"
                        >
                            <b-col
                                sm="8"
                            >
                                <b-button
                                    variant="primary"
                                    :disabled="loading"
                                    @click="saveGroup"
                                >
                                    <template v-if="loading">
                                        <b-spinner small type="grow" />
                                    </template>
                                    <template v-else>
                                        <span>
                                            Save
                                        </span>
                                    </template>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-tab>
                <b-tab>
                    <template #title>
                        <feather-icon icon="UsersIcon" />
                        <span>Users</span>
                    </template>
                    <b-container
                        clas="mt-2"
                        fluid
                    >
                        <div
                            v-if="!loading"
                        >
                            <b-card
                                title="Current group users"
                            >
                                <b-row
                                    v-if="usersForList.length > 0"
                                >
                                    <b-col
                                        v-for="(user, index) in usersForList"
                                        :key="index"
                                        cols="3"
                                    >
                                        <b-card
                                            :bg-variant="isDark ? 'dark' : 'light'"
                                            :style="isDark ? 'background: #161d31 !important;' : 'background: #f6f6f6 !important;'"
                                            text-variant="dark"
                                        >
                                            <div class="text-center">
                                                <avatar
                                                    b-avatar-size="50"
                                                    :full-user-name="user.name"
                                                    class="mb-1"
                                                />
                                            </div>

                                            <div class="text-center">
                                                {{ user.name }}
                                                <br>
                                                {{ user.email }}
                                            </div>

                                            <div class="text-center mt-1">
                                                <b-button
                                                    v-b-modal.removeUserFromGroup
                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                    variant="outline-danger"
                                                    class="btn-icon rounded-circle"
                                                    :disabled="!(usersForList.length === 1)"
                                                    @click="selectUserForRemoval(user.id)"
                                                >
                                                    <feather-icon icon="XIcon" />
                                                </b-button>
                                            </div>
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <b-row
                                    v-else
                                >
                                    <b-col
                                        cols="12"
                                    >
                                        <b-alert
                                            variant="danger"
                                            :show="users.length <= 0"
                                        >
                                            <h4
                                                class="alert-heading"
                                            >
                                                No users found!
                                            </h4>
                                            <div
                                                class="alert-body"
                                            >
                                                There are no users that belong to this group.
                                            </div>
                                        </b-alert>
                                    </b-col>
                                </b-row>
                            </b-card>
                            <b-card
                                title="Add users to group"
                            >
                                <b-row
                                    v-if="users.length > 0"
                                >
                                    <b-col
                                        v-for="(user, index) in users"
                                        :key="index"
                                        cols="3"
                                    >
                                        <b-card
                                            :bg-variant="isDark ? 'dark' : 'light'"
                                            :style="isDark ? 'background: #161d31 !important;' : 'background: #f6f6f6 !important;'"
                                            text-variant="dark"
                                        >
                                            <div class="text-center">
                                                <avatar
                                                    b-avatar-size="50"
                                                    :full-user-name="user.name"
                                                    class="mb-1"
                                                />
                                            </div>

                                            <div class="text-center">
                                                {{ user.name }}
                                                <br>
                                                {{ user.email }}
                                            </div>

                                            <div class="text-center mt-1">
                                                <b-button
                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                    variant="outline-success"
                                                    class="btn-icon rounded-circle"
                                                    @click="userAddedCallback(user)"
                                                >
                                                    <feather-icon icon="PlusIcon" />
                                                </b-button>
                                            </div>
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <b-row
                                    v-else
                                >
                                    <b-col
                                        cols="12"
                                    >
                                        <b-alert
                                            variant="danger"
                                            :show="users.length <= 0"
                                        >
                                            <h4
                                                class="alert-heading"
                                            >
                                                No users found!
                                            </h4>
                                            <div
                                                class="alert-body"
                                            >
                                                We didn't find any users that can be added to this group!
                                            </div>
                                        </b-alert>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </div>
                        <div
                            v-else
                            class="row mx-auto justify-content-center align-items-center flex-column"
                        >
                            <b-spinner
                                style="width: 2rem; height: 2rem; margin-top: 20px;"
                                variant="primary"
                            />
                        </div>
                    </b-container>
                </b-tab>
                <b-tab>
                    <template #title>
                        <feather-icon icon="LayersIcon" />
                        <span>Permissions</span>
                    </template>
                    <permissions-list
                        v-if="!loading"
                        ref="permission-list"
                        :current-permissions="groupData.permissions"
                        @permission-updated="saveGroupPermissions"
                    />
                </b-tab>
            </b-tabs>
        </b-card>
        <modal-confirm-vuexy
            reference="removeUserFromGroup"
            modal-id="removeUserFromGroup"
            text="Are you sure you want to remove this user from the user group?"
            title="Remove user from group?"
            :ok-function="removeUserFromGroup"
        />
    </div>
</template>

<script>
import Axios from 'axios'
import PermissionsList from '@/components/Permissions/PermissionList.vue'
import Permission from '@/structures/Permission'
import Avatar from '@/components/Misc/Avatar'
import Ripple from 'vue-ripple-directive'
import {
    BTab,
    BTabs,
    BCard,
    BFormInput,
    BContainer,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BAlert,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import ModalConfirmVuexy from '@/components/Modals/ModalConfirmVuexy'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    name: 'settings.user-groups.create',
    components: {
        BTab,
        BTabs,
        BCard,
        BFormInput,
        BContainer,
        BCol,
        BRow,
        BButton,
        BSpinner,
        BAlert,
        Avatar,
        ModalConfirmVuexy,
        PermissionsList,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            errors: [],
            groupSaved: false,
            groupData: {
                name: '',
                users: [],
                permissions: {},
            },
            loading: false,
            currentTab: 'general',
            userIdToRemove: 0,
            userRemoved: false,
            userAdded: false,
            addUserModalOpen: false,
            loadedUsers: [],
        }
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
    computed: {
        currentUserIds() {
            return this.groupData.users.map(user => user.id)
        },
        users() {
            return this
                .loadedUsers
                .filter(user => this.currentUserIds.indexOf(user.id) === -1)
        },
        usersForList() {
            const users = JSON.parse(JSON.stringify(this.groupData.users))
            const sort = (a, b) => {
                if (a.name > b.name) {
                    return 1
                }
                if ((b.name > a.name)) {
                    return -1
                }

                return 0
            }
            return users.sort(sort)
        },
    },
    created() {
        this.loading = true
        this.loadGroup()
        this.loadUsers()
    },
    methods: {
        loadGroup() {
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}user-groups/view`,
                data: { id: this.$route.params.id },
                method: 'POST',
            })
                .then(response => {
                    this.groupData.name = response.data.name
                    this.groupData.id = response.data.id
                    this.groupData.users = response.data.users
                    this.groupData.permissions = response.data.permissions
                    this.groupData.isdeletable = response.data.is_deletable
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        saveGroup() {
            this.loading = true
            this.groupSaved = false
            this.errors = []
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}user-groups/update`,
                data: this.groupData,
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                    this.groupSaved = true
                })
                .catch(err => {
                    for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                        const errorGroup = err.response.data.errors[errorGroupKey]
                        this.errors = this.errors.concat(errorGroup)
                    }
                    this.loading = false
                })
        },
        selectUserForRemoval(id) {
            this.userIdToRemove = id
        },
        removeUserFromGroup() {
            this.removeUserModalLoading = true
            this.userRemoved = false
            this.userAdded = false
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}user-groups/remove-user`,
                data: { group_id: this.$route.params.id, user_id: this.userIdToRemove },
                method: 'POST',
            })
                .then(() => {
                    this.userRemoved = true
                    this.groupData.users = this.groupData.users.filter(user => user.id !== this.userIdToRemove)
                    this.userIdToRemove = 0

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success',
                            icon: 'CheckIcon',
                            text: 'User removed from this group successfully!',
                            variant: 'success',
                        },
                    })
                })
                .catch(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Whoops!',
                            icon: 'XIcon',
                            text: 'This user cannot be removed for this time.',
                            variant: 'danger',
                        },
                    })
                })
        },
        userAddedCallback(user) {
            this.loading = true
            this.userAdded = false
            this.userRemoved = false
            this.addUserModalOpen = false
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}user-groups/add-user`,
                data: { group_id: this.$route.params.id, user_id: user.id },
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                    this.groupData.users.push(user)
                    this.userAdded = true

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success',
                            icon: 'CheckIcon',
                            text: 'User has been added to the group successfully!',
                            variant: 'success',
                        },
                    })
                })
                .catch(() => {
                    this.loading = false

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Whoops!',
                            icon: 'XIcon',
                            text: 'This user cannot be added for this time.',
                            variant: 'danger',
                        },
                    })
                })
        },
        loadUsers() {
            Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}users/all`, data: {}, method: 'POST' })
                .then(resp => {
                    this.loading = false
                    this.loadedUsers = resp.data
                })
                .catch(() => {
                    this.loading = false
                    this.loadedUsers = []
                })
        },
        saveGroupPermissions() {
            let permissions = this.$refs['permission-list'].getUpdatedPermissions()
            permissions = Permission.prepareForSaving(permissions)
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}user-groups/save-permissions`,
                data: { id: this.$route.params.id, permissions },
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
}
</script>
